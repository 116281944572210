import { action } from 'typesafe-actions';

import {
  IAttachCheckoutId,
  IAttachDocumentDownloadUrl,
  IAttachPaymentCode,
  IAttachPaymentMethod,
  IAttachPaymentMethodFailure,
  IAttachPaymentMethodSuccess,
  IPaymentActionTypes,
  ISetPaymentErrorAction,
  ISetPaymentLoadingAction,
} from './types';

export const setPaymentLoadingAction = (
  isLoading?: ISetPaymentLoadingAction['payload']['isLoading'],
): any => // ReturnType<typeof action>
  action<ISetPaymentLoadingAction['type'], ISetPaymentLoadingAction['payload']>(
    IPaymentActionTypes.SET_LOADING,
    { isLoading },
  );
export const setPaymentErrorAction = (
  error: ISetPaymentErrorAction['payload']['error'],
): any =>
  action<ISetPaymentErrorAction['type'], ISetPaymentErrorAction['payload']>(
    IPaymentActionTypes.SET_ERROR,
    { error },
  );

export const attachPaymentMethodAction = (): any =>
  action<IAttachPaymentMethod['type']>(
    IPaymentActionTypes.ATTACH_PAYMENT_METHOD_REQUEST,
  );

export const attachPaymentMethodSuccessAction = (
  payload: IAttachPaymentMethodSuccess['payload'],
): any =>
  action<
    IAttachPaymentMethodSuccess['type'],
    IAttachPaymentMethodSuccess['payload']
  >(IPaymentActionTypes.ATTACH_PAYMENT_METHOD_SUCCESS, payload);

export const attachPaymentMethodFailureAction = (
  error: IAttachPaymentMethodFailure['payload']['error'],
): any =>
  action<
    IAttachPaymentMethodFailure['type'],
    IAttachPaymentMethodFailure['payload']
  >(IPaymentActionTypes.ATTACH_PAYMENT_METHOD_FAILURE, { error });

export const attachPaymentCheckoutIdAction = (checkoutId: string): any =>
  action<IAttachCheckoutId['type'], IAttachCheckoutId['payload']>(
    IPaymentActionTypes.ATTACH_CHECKOUT_ID,
    { checkoutId },
  );

export const attachPaymentCodeAction = (code: string): any =>
  action<IAttachPaymentCode['type'], IAttachPaymentCode['payload']>(
    IPaymentActionTypes.ATTACH_PAYMENT_CODE,
    { code },
  );

export const attachDocumentDownloadUrlAction = (
  documentDownloadUrl: string,
): any =>
  action<
    IAttachDocumentDownloadUrl['type'],
    IAttachDocumentDownloadUrl['payload']
  >(IPaymentActionTypes.ATTACH_DOWNLOAD_URL, { documentDownloadUrl });
