/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { action } from 'typesafe-actions';
import { Order } from '@commercelayer/sdk';

import {
  OrderTypes,
  ILoadOrderSuccessAction,
  ILoadProductsAction,
  IOrderDetails,
  IAddProductAction,
  IAddProductActionPayload,
  IChangeProductQuantityAction,
  IRemoveProductAction,
  OrderStatuses,
  IUpdateNotificationAction,
} from 'shared/store/order/types';
import { IPaymentMethod } from 'shared/infra/commerceLayer/types';

export const loadOrderSuccess = (
  response: ILoadOrderSuccessAction['payload'],
) => action(OrderTypes.LOAD_ORDER_SUCCESS, response);

export const loadOrderRequest = () => action(OrderTypes.LOAD_ORDER_REQUEST);

export const cloneOrderRequest = () => action(OrderTypes.LOAD_ORDER_REQUEST);

export const loadOrderFailure = (message: string) =>
  action(OrderTypes.LOAD_ORDER_FAILURE, { message });

export const loadProducts = (response: ILoadProductsAction['payload']) =>
  action(OrderTypes.LOAD_PRODUCTS, response);

export const addProduct = (contentfulId: string) =>
  action<IAddProductAction['type'], IAddProductActionPayload>(
    OrderTypes.ADD_PRODUCT_REQUEST,
    {
      contentfulId,
    },
  );

export const addProductSuccess = (message: string) =>
  action(OrderTypes.ADD_PRODUCT_SUCCESS, { message });

export const addProductFailure = (message: string) =>
  action(OrderTypes.ADD_PRODUCT_FAILURE, { message });

export const proposeBusinessAccount = (value: boolean) =>
  action(OrderTypes.PROPOSE_BUSINESS_ACCOUNT, { value });

export const selectBusinessAccount = (value: boolean) =>
  action(OrderTypes.SELECT_BUSINESS_ACCOUNT, { value });

export const placeOrder = (orderId: string, locale: string) =>
  action(OrderTypes.PLACE_ORDER_REQUEST, {
    orderId,
    locale,
  });

export const placeOrderSuccess = (status = OrderStatuses.placed) =>
  action(OrderTypes.PLACE_ORDER_SUCCESS, { order: { status } });

export const placeOrderFailure = (message: string) =>
  action(OrderTypes.PLACE_ORDER_FAILURE, { message });

export const cloneOrder = (
  paymentMethod: IPaymentMethod,
  loadLocalOrderFn: () => Promise<Order>,
  errorMessage?: string,
  errorCode?: string,
  country?: string,
) =>
  action(OrderTypes.CLONE_ORDER_REQUEST, {
    paymentMethod,
    country,
    errorMessage,
    errorCode,
    loadLocalOrderFn: loadLocalOrderFn,
  });

export const cloneOrderSuccess = () => action(OrderTypes.CLONE_ORDER_SUCCESS);

export const cloneOrderFailure = (message: string) =>
  action(OrderTypes.CLONE_ORDER_FAILURE, { message });

export const applyCoupon = (couponCode: string) =>
  action(OrderTypes.APPLY_COUPON_REQUEST, {
    couponCode,
    updateCouponLimits: true,
  });

export const resetCoupon = () => action(OrderTypes.RESET_COUPON_REQUEST);

export const applyCouponSuccess = (order: IOrderDetails) =>
  action(OrderTypes.APPLY_COUPON_SUCCESS, { order });

export const applyCouponFailure = (errorMessage: string) =>
  action(OrderTypes.APPLY_COUPON_FAILURE, { errorMessage });

type IChangeProductQuantityDispatchAction = {
  // TODO IChangeProductQuantityAction['payload']
  (lineItemId: string, quantity: number, contentfulId: string): ReturnType<
    typeof action
  >;
};
export const changeProductQuantity: IChangeProductQuantityDispatchAction = (
  lineItemId,
  quantity,
  contentfulId,
) =>
  action<
    IChangeProductQuantityAction['type'],
    IChangeProductQuantityAction['payload']
  >(OrderTypes.CHANGE_PRODUCT_QUANTITY, { lineItemId, quantity, contentfulId });

export const changeProductQuantitySuccess: { (): ReturnType<typeof action> } =
  () => action(OrderTypes.CHANGE_PRODUCT_QUANTITY_SUCCESS, {});

export const changeProductQuantityFailure: {
  (errorMessage: string): ReturnType<typeof action>;
} = (errorMessage) =>
  action(OrderTypes.CHANGE_PRODUCT_QUANTITY_FAILURE, { errorMessage });

export const removeProduct = (contentfulId: string) =>
  action<IRemoveProductAction['type'], IRemoveProductAction['payload']>(
    OrderTypes.REMOVE_PRODUCT,
    { contentfulId },
  );

export const removeProductSuccess: { (): ReturnType<typeof action> } = () =>
  action(OrderTypes.REMOVE_PRODUCT_SUCCESS, {});

export const removeProductFailure: {
  (errorMessage: string): ReturnType<typeof action>;
} = (errorMessage) =>
  action(OrderTypes.REMOVE_PRODUCT_FAILURE, { errorMessage });

export const updateNotification = (payload) =>
  action<
    IUpdateNotificationAction['type'],
    IUpdateNotificationAction['payload']
  >(OrderTypes.UPDATE_NOTIFICATION, payload);

export const resetOrder: { (): ReturnType<typeof action> } = () =>
  action(OrderTypes.RESET_ORDER, {});
