import { action } from 'typesafe-actions';

import {
  AddressTypes,
  ISaveAddressesAction,
  IStoreAddressAction,
  ISaveAddressesSuccessAction,
  ISaveAddressesFailureAction,
} from 'shared/store/address/types';

export const storeAddressAction = (
  payload: IStoreAddressAction['payload'],
): ReturnType<typeof action> => action(AddressTypes.STORE_ADDRESSES, payload);

export const saveAddressesAction = (
  payload: ISaveAddressesAction['payload'],
): any =>
  action<ISaveAddressesAction['type'], ISaveAddressesAction['payload']>(
    AddressTypes.SAVE_ADDRESSES_REQUEST,
    payload,
  );

export const saveAddressesSuccessAction = (
  payload: ISaveAddressesSuccessAction['payload'],
): ReturnType<typeof action> =>
  action<
    ISaveAddressesSuccessAction['type'],
    ISaveAddressesSuccessAction['payload']
  >(AddressTypes.SAVE_ADDRESSES_SUCCESS, payload);

export const saveAddressesFailureAction = (
  error: ISaveAddressesFailureAction['payload']['error'],
): ReturnType<typeof action> =>
  action<
    ISaveAddressesFailureAction['type'],
    ISaveAddressesFailureAction['payload']
  >(AddressTypes.SAVE_ADDRESSES_FAILURE, { error });
